var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("i", { staticClass: "fas fa-code" }),
        _vm._v(" Example Vue Component\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm._v("\n        I'm an example Vue component!\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }