
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../bootstrap';
import '../plugins';
import Vue from 'vue';
import {fullpage} from 'fullpage.js';

window.Vue = Vue;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

// $('.cardlogin label').addEventListener('selectstart', () => {
//     console.log('Selection started'); 
//   });
// onselectstart 
// var labels = $('.carlogin label');
// labels.onselectstart(function(){
//     console.log('yeah');
// });

//login
// $(document).on('select', '.cardlogin label', function(e){
//     // e.preventDefault();
//     $(this).addClass('active');
// });

// Validar email con expresión regular
function comprobar_mail(email)
{
    var expresion = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return expresion.test(email);
}

$(document).on('focusin click', '.cardlogin input, .cardlogin label', function(e){
    e.preventDefault();
    console.log($(this).hasClass('no-select-av'));
    if($(this).hasClass('no-select-av'))
        $(this).parent().find('input').focus();
    
    $(this).parent().find('label').addClass('active');
    
    
})
$(document).on('focusout', '.cardlogin input', function(e){
    e.preventDefault();
    if($(this).val() == '')
        $(this).parent().find('label').removeClass('active');

    $('.failvalid').remove();
})
// $(document).on('click', ':not(label)' )
$(document).ready(function() {
    // var fullPageInstance = new fullpage('#fullpage', {
    //     anchors: ['firstPage', 'secondPage', '3rdPage'],
    //     sectionsColor: ['#8FB98B', '#DE564B', '#EAE1C0'],
    //     slidesNavigation: true,
    // });
     var fullpagelogin = $('#fullpage').fullpage({
        lockAnchors: false,
        css3: true,
        scrollBar: false,
        controlArrows: false,
        autoScrolling: false,
        fitToSection: false,
        scrollingSpeed: 400,
        dragAndMove: false,
        onLeave: function(origin, destination, direction){
            console.log(destination);
            //it won't scroll if the destination is the 3rd section
            if(destination.index == 2){
                return false;
            }
        }
    });

    // $('input').each(function(t, i, e){
    //     console.log($(this).val());
    //     if($(this).val() != ""){
    //         $(this).parent().find('label').addClass('active');
    //         $(this).focus();
    //     }
    // })
    $('#email').focus();
});

$(document).on('click', '.btnnext', validateMail);

function validateMail(){
    if(comprobar_mail($('input[name="email"]').val())){
        
        $.fn.fullpage.moveTo(1,1);
        $('input[name="password"]').focus();
    }else{
        $(this).parent().parent().parent().parent().find('input').addClass('failed-validation');
        $(this).parent().parent().parent().parent().find('label').css('color', 'red');
        if(!$('.failvalid').length)
            $(this).parent().parent().parent().parent().find('label').after('<span class="failvalid" style="color:red"><em>Correo inválido</em></span>')
    }
}

$(document).on('click', '.btnprev', function(){
    $.fn.fullpage.moveTo(1,0);
})
// var myFullpage = new fullpage('#fullpage', {
//     anchors: ['firstPage', 'secondPage', '3rdPage'],
//     sectionsColor: ['#8FB98B', '#DE564B', '#EAE1C0'],
//     slidesNavigation: true,
// });
